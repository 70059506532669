*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;
    color: white;
}

:root{
    --mainColor: rgba(74, 128, 54, 0.91);
    --mainColorLight: #295c56;
    --secondaryColor: rgb(67, 136, 62);
    /*Navbar text color*/
    --textColor: rgba(62, 79, 178, 0.89);

    /*--phone0: ;*/
}

html{
    scroll-behavior: smooth;
}

/*Navbar*/
header {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    width: 100%;
    padding: 0 2rem;
    background: linear-gradient(0deg, rgba(156, 206, 136, 0.63) 0%, rgba(102, 187, 93, 0.63) 100%);
    /*color: var(--textColor);*/
    z-index: 1;
}

nav a{
    margin: 0 2rem;
    text-decoration: none;
    font-size: 1.2rem;
    /*Nav button color*/
    color: rgba(0, 0, 0, 0.9);
}

nav a:hover{
    /*Nav button hover color bigger screen*/
    color: #295c56;
}

header .nav-btn {
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    /*color: #650c0c;*/
    visibility: hidden;
    opacity: 0;
    font-size: 1.8rem;
}


.call-buton .cc-calto-action-ripple {
	z-index: 99999;
	position: fixed;
	right: 1rem;
	bottom: 8rem;
	background: rgba(156, 206, 136, 0.63);
	width: 4rem;
	height: 4rem;
	padding: 1rem;
	border-radius: 100%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-animation: cc-calto-action-ripple 0.6s linear infinite;
	animation: cc-calto-action-ripple 0.6s linear infinite;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	justify-items: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-decoration: none; }
.call-buton .cc-calto-action-ripple i {
	  -webkit-transition: 0.3s ease;
	  transition: 0.3s ease;
	  font-size: 1.8rem;
    }

.call-buton .cc-calto-action-ripple:hover i {
	  -webkit-transform: rotate(135deg);
	  transform: rotate(-135deg);
}

@-webkit-keyframes cc-calto-action-ripple {
	0% {
	  -webkit-box-shadow: 0 4px 10px rgba(0, 236, 4, 0.2), 0 0 0 0 rgba(0, 236, 20, 0.2), 0 0 0 5px rgba(0, 236, 12, 0.2), 0 0 0 10px rgba(0, 236, 31, 0.2);
	  box-shadow: 0 4px 10px rgba(0, 236, 4, 0.2), 0 0 0 0 rgba(0, 236, 20, 0.2), 0 0 0 5px rgba(0, 236, 12, 0.2), 0 0 0 10px rgba(0, 236, 31, 0.2); }
	100% {
	  -webkit-box-shadow: 0 4px 10px rgba(13, 59, 13, 0.2), 0 0 0 0 rgba(0, 236, 20, 0.2), 0 0 0 5px rgba(129, 199, 134, 0.2), 0 0 0 10px rgba(31, 82, 40, 0.2);
	  box-shadow: 0 4px 10px rgba(13, 59, 13, 0.2), 0 0 0 0 rgba(0, 236, 20, 0.2), 0 0 0 5px rgba(129, 199, 134, 0.2), 0 0 0 10px rgba(31, 82, 40, 0.2); } }

@keyframes cc-calto-action-ripple {
	0% {
	  -webkit-box-shadow: 0 4px 10px rgba(0, 236, 4, 0.2), 0 0 0 0 rgba(0, 236, 20, 0.2), 0 0 0 5px rgba(0, 236, 12, 0.2), 0 0 0 10px rgba(0, 236, 31, 0.2);
	  box-shadow: 0 4px 10px rgba(0, 236, 4, 0.2), 0 0 0 0 rgba(0, 236, 20, 0.2), 0 0 0 5px rgba(0, 236, 12, 0.2), 0 0 0 10px rgba(0, 236, 31, 0.2); }
	100% {
	  -webkit-box-shadow: 0 4px 10px rgba(13, 59, 13, 0.2), 0 0 0 0 rgba(0, 236, 20, 0.2), 0 0 0 5px rgba(129, 199, 134, 0.2), 0 0 0 10px rgba(31, 82, 40, 0.2);
	  box-shadow: 0 4px 10px rgba(13, 59, 13, 0.2), 0 0 0 0 rgba(0, 236, 20, 0.2), 0 0 0 5px rgba(129, 199, 134, 0.2), 0 0 0 10px rgba(31, 82, 40, 0.2); } }


@media only screen and (max-width: 768px){
    header .nav-btn {
        padding: 10px;
    }
}

@media only screen and (max-width: 1024px){
    header .nav-btn {
        visibility: visible;
        opacity: 1;
        /*color: #9f3030;*/
    }

    header nav{
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        /*mobile nav menu color*/
        background-color: rgba(54, 100, 42, 0.9);
        transition: 1s;
        transform: translateY(-200vh);
    }

    header .responsive_nav{
        transform: none;
    }

    nav .nav-close-btn {
        position: absolute;
        top: 2rem;
        right: 2rem;
        color: black;
    }

    nav a{
        font-size: 1.5rem;
        color: #000000;
    }

}