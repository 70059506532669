:root{
    --pricesPhone800e: 1050px;
    --locationPhone800e: 1430px;

    --pricesPhone700e: 1050px;
    --locationPhone700e: 1500px;

    --pricesPhone600e: 1100px;
    --locationPhone600e: 1500px;

    --boxPricesPhoneSmall: 1250px;
    --googleBoxPhoneSmall: 1750px;

    --boxPricesPhone: 1230px;
    --googleBoxPhone: 1670px;

    --logoTopMainScreen: 65px;
    --slideshowMainScreen: 200px;
    --boxAboutUsMainScreen: 550px;
    --boxPricesMainScreen: 800px;
    --googleBoxMainScreen: 1200px;
    --widthMainScreen: 70%;

    --logoTopSmallScreen: 75px;
    --slideshowSmallScreen: 300px;
    --boxAboutUsSmallScreen: 650px;
    --boxPricesSmallScreen: 1000px;
    --googleBoxSMallScreen: 1350px;
    --widthSmallScreen: 90%;

    --logoTopBigScreen: 60px;
    --slideshowBigScreen: 200px;
    --boxAboutUsBigScreen: 550px;
    --boxPricesBigScreen: 800px;
    --googleBoxBigScreen: 1200px;
    --widthBigScreen: 60%;

    --mainBoxColor: rgba(40, 98, 36, 0.12);
}

*{
    margin: 0;
    box-sizing: border-box;
    /*nav menu open/close button*/
    color: #25421d;
}

@font-face {
    font-family: 'Merianda';
}

.landingpage{
    width: 100%;
    /*height: 100vh;*/
    padding-top: 45px;
    padding-bottom: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    transition: 0.5s;
    font-family: serif;
    /*overflow: hidden;*/
    /*flex: 1;*/
    min-height: 0px;
}



/*.front_image_text{*/
/*    padding-top: 10%;*/
/*    text-align: center;*/
/*    font-size: 3em;*/
/*    font-family: 'Comic Sans MS', serif;*/
/*    animation-name: fade;*/
/*    animation-duration: 2s;*/
/*    color: #282c34;*/
/*}*/

/*.video-bg{*/
/*    width: 100%;*/
/*    height: 100vh;*/
/*    position: absolute;*/
/*    top: 0;*/
/*    z-index: -1;*/
/*    object-fit: cover;*/
/*    background-color: rgb(196, 255, 208);*/
/*}*/

.bg-overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(141, 217, 141, 0.1);
    position: absolute;
    top: 0;
    z-index: 0;
}

.navbar {
    width: 100%;
    height: 6em;
    /*border-bottom: 2px solid white;*/
    position: absolute;
    top: 0;
    left: 0;
    /*display: flex;*/
    display: block;
    justify-content: flex-start;
    align-items: center;
    z-index: 1;
}

.menu {
    height: 3em;
    width: 4em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 2em;
    cursor: pointer;
}

.menu div {
    width: 90%;
    height: 0.25em;
    /*background-color: #6e3030;*/
    border-radius: 1em;
}

/*.menu div:nth-of-type(2) {width: 40%}*/
/*.menu div:nth-of-type(3) {width: 40%}*/

.front_image{
    position: absolute;
    top: var(--logoTopMainScreen);
    background-image: url("../media/logo_navbar.avif");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 80%;
    height: 160%;
}

.slideshow{
    top: var(--slideshowMainScreen);
    width: var(--widthMainScreen);
    height: 200%;
    background-color: var(--mainBoxColor);
    overflow: hidden;
    position: relative;
    margin: 0 auto;
}

.slideshow-content{
    list-style: none;
    height: 100%;
    display: flex;
    /*animation: scrolling 20s linear infinite;*/
    animation: scrolling 40s linear infinite;
    width: calc(250px*2);
}

/*.slideshow-content:hover{*/
/*    animation-play-state: paused;*/
/*}*/

@keyframes scrolling {
    0% {transform: translateX(0);}
    100% {transform: translateX(calc(-250px*9));}
}

.slideshow-content li{
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*width: 100%;*/
    /*height: 100%;*/
    /*flex-shrink: 0;*/
    /*font-size: 5rem;*/
    /*white-space: nowrap;*/
    /*position: relative;*/
}

.slideshow-content li img{
    /*width: 200%;*/
    /*height: 200%;    */
    width: 250px;
    height: 250px;
    border: 2px solid #ffffff;
    float: left;
    perspective: 400px;
}

.home-text {
    position: absolute;
    top: var(--boxAboutUsMainScreen);
    width: var(--widthMainScreen);
    /*height: 80%;*/
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    justify-content: space-around;
    z-index: 0;
    background: var(--mainBoxColor);
    padding-left: 40px;
    padding-right: 40px;
    /*padding-bottom: 20%;*/
}

.home-prices_en {
    position: absolute;
    top: var(--boxPricesMainScreen);
    width: var(--widthMainScreen);
    /*height: 80%;*/
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    justify-content: space-around;
    z-index: 0;
    background: var(--mainBoxColor);
    padding-left: 40px;
    padding-right: 40px;
    /*padding-bottom: 20%;*/
}

.home-prices_en li {
    text-align: left;
    position: relative;
    left: 150px;
}

.home-prices_en li::marker {
    color: #62981f;
}

.home-google {
    position: absolute;
    top: var(--googleBoxMainScreen);
    width: var(--widthSmallScreen);
    /*height: 20%;*/
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    justify-content: space-around;
    z-index: 0;
    background: var(--mainBoxColor);
    padding-left: 40px;
    padding-right: 40px;

}

.list-items{
    align-items: start;
}

.google-maps iframe{
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
}

.google-maps{
    display: flex;
}

/*------- Animation -------*/
@keyframes fade {
    0%{
        opacity: 0;
        margin-left: 3em;
    }
    100%{
        opacity: 1;
    }
}

@media only screen and (min-width: 1500px){
    .front_image{
        position: absolute;
        top: var(--logoTopBigScreen);
        width: 70%;
        height: 50%;
    }

    .slideshow {
        top: var(--slideshowBigScreen);
        width: var(--widthBigScreen);
    }

    .home-text {
        position: absolute;
        top: var(--boxAboutUsBigScreen);
        width: var(--widthBigScreen);
    }

    .home-prices_en {
        position: absolute;
        top: var(--boxPricesBigScreen);
        width: var(--widthBigScreen);
    }

    .home-prices_en li {
        text-align: left;
        position: relative;
        left: 400px;
    }

    .home-google{
        top: var(--googleBoxBigScreen);
        width: var(--widthBigScreen);
        height: 100%;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1500px ) {
    .front_image{
        position: absolute;
        top: var(--logoTopMainScreen);
        width: 70%;
        height: 50%;
    }

    .slideshow {
        top: var(--slideshowMainScreen);
        width: var(--widthMainScreen);
    }

    .home-prices_en {
        top: var(--boxPricesMainScreen);
    }

    .home-prices_en li {
        text-align: left;
        position: relative;
        left: 300px;
    }

    .home-google{
        top: var(--googleBoxMainScreen);
        width: var(--widthMainScreen);
        height: 100%;
    }
}

@media only screen and (max-width: 1024px) {
    .landingpage{
        /*padding-top: 15%;*/
        padding-bottom: 5%;
    }

    .front_image_text {
        padding-top: 15%;
    }

    .front_image{
        top: var(--logoTopSmallScreen);
        height: 70%;
        width: 90%;
    }

    .slideshow {
        top: var(--slideshowSmallScreen);
        width: var(--widthSmallScreen);
    }

    .home-text {
        top: var(--boxAboutUsSmallScreen);
        width: 80%;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 30px;
    }

    .home-prices_en {
        top: var(--boxPricesSmallScreen);
        width: 80%;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 30px;
    }

    .home-google{
        top: var(--googleBoxSMallScreen);
        height: 65%;
        width: var(--widthSmallScreen);
    }

    .google-maps iframe{
        width: 100%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .google-maps{
        display: flex;
    }

}

@media only screen and (max-width: 800px){
    .home-prices_en {
        top: var(--pricesPhone800e);
    }

    .home-prices_en li {
        text-align: left;
        position: relative;
        /*left: 200px;*/
    }

    .home-google{
        top: var(--locationPhone800e);
    }
}

@media only screen and (max-width: 700px){
    .home-prices_en {
        top: var(--pricesPhone700e);
    }

    .home-prices_en li {
        text-align: left;
        position: relative;
        left: 80px;
    }

    .home-google{
        top: var(--locationPhone700e);
    }
}

@media only screen and (max-width: 600px){
    .home-prices_en {
        top: var(--pricesPhone600e);
    }

    .home-prices_en li {
        text-align: left;
        position: relative;
        left: 80px;
    }

    .home-google{
        top: var(--locationPhone600e);
    }
}

@media only screen and (max-width: 500px){
    .home-prices_en {
        top: var(--boxPricesPhone);
    }

    .home-prices_en li {
        text-align: left;
        position: relative;
        left: 60px;
    }

    .home-google{
        top: var(--googleBoxPhone);
    }
}

@media only screen and (max-width: 400px){
    .home-prices_en {
        top: var(--boxPricesPhoneSmall);
    }

    .home-google{
        top: var(--googleBoxPhoneSmall);
    }
}