*{
  margin: 0;
  padding: 0;
  font-family: Impact, Haettenschweiler, 'Arial Rounded MT Bold', sans-serif;
}

/*body {*/
/*  overflow: hidden;*/
/*}*/

.bgContainer {
  width: 100%;
  height: 100%;
}

/*video {*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  object-fit: cover;*/
/*}*/

.container {
  position: absolute;
  width: 77%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}

h1{
    font-size: 2.5em;
    font-family: 'Comic Sans MS', serif;
    animation-name: fade;
    animation-duration: 2s;
    color: #282c34;
}

h2{
  font-size: 20px;
  font-weight: 700;
  height: 80%;
  animation: ease-out;
  animation-duration: 4s;
  text-align: center;
}

h3{
    color: rgba(0, 0, 0, 0.77);
}

p{
  color: rgba(0, 0, 0, 0.73);
  text-align: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
}

.line{
    border-bottom: 3px solid rgba(0, 0, 0, 0.24);
}


@media only screen and (max-width: 1024px) {
    h1 {
        font-size: 1.8em;
        text-align: center;
    }

    h2 {
        font-size: 1.2em;
        text-align: center;
    }

}
