:root{
    /*<400*/
    --pricesPhoneSmall: 1500px;
    --googlePhoneSmall: 2150px;
    /*<500*/
    --pricesPhone: 1350px;
    --googlePhone: 1950px;

    --pricesPhone800: 1080px;
    --locationPhone800: 1500px;

    --pricesPhone700: 1150px;
    --locationPhone700: 1700px;

    --pricesPhone600: 1250px;
    --locationPhone600: 1850px;

    /*1024-1500*/
    --logoMainScreen: 65px;
    --slideshowMainScr: 200px;
    --boxAboutUsMainScr: 550px;
    --boxPricesMainScr: 930px;
    --googleBoxMainScr: 1350px;
    --widthMainScr: 70%;

    /*<1024*/
    --logoTopSmallScr: 75px;
    --slideshowSmallScr: 250px;
    --boxAboutUsSmallScr: 560px;
    --boxPricesSmallScr1024: 1050px;
    --googleBoxSMallScr: 1450px;
    --widthSmallScr: 90%;

    /*>1500*/
    --logoTopBigScr: 60px;
    --slideshowBigScr: 200px;
    --boxAboutUsBigScr: 500px;
    --boxPricesBigScr: 850px;
    --googleBoxBigScr: 1300px;
    --widthBigScr: 60%;

    --mainBoxColor: rgba(40, 98, 36, 0.12);
}

*{
    margin: 0;
    box-sizing: border-box;
    /*nav menu open/close button*/
    color: #25421d;
}

@font-face {
    font-family: 'Merianda';
}

.landingpage{
    width: 100%;
    /*height: 100vh;*/
    padding-top: 45px;
    padding-bottom: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    transition: 0.5s;
    font-family: serif;
    /*overflow: hidden;*/
    /*flex: 1;*/
    min-height: 0px;
}

.bg-overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(141, 217, 141, 0.1);
    position: absolute;
    top: 0;
    z-index: 0;
}

.navbar {
    width: 100%;
    height: 6em;
    /*border-bottom: 2px solid white;*/
    position: absolute;
    top: 0;
    left: 0;
    /*display: flex;*/
    display: block;
    justify-content: flex-start;
    align-items: center;
    z-index: 1;
}

.menu {
    height: 3em;
    width: 4em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 2em;
    cursor: pointer;
}

.menu div {
    width: 90%;
    height: 0.25em;
    /*background-color: #6e3030;*/
    border-radius: 1em;
}

.front_image{
    position: absolute;
    top: var(--logoMainScreen);
    background-image: url("../media/logo_navbar_resize.avif");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 80%;
    height: 160%;
}

.slideshow{
    top: var(--slideshowMainScr);
    width: var(--widthMainScr);
    height: 200%;
    background-color: var(--mainBoxColor);
    overflow: hidden;
    position: relative;
    margin: 0 auto;
}

.slideshow-content{
    list-style: none;
    height: 100%;
    display: flex;
    animation: scrolling 10s linear infinite;
    /*width: calc(250px*2);*/
}

@keyframes scrolling {
    0% {transform: translateX(0);}
    100% {transform: translateX(calc(-250px*9));}
}

.slideshow-content li{
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*width: 100%;*/
    /*height: 100%;*/
    /*flex-shrink: 0;*/
    /*font-size: 5rem;*/
    /*white-space: nowrap;*/
    /*position: relative;*/
}

.slideshow-content li img{
    /*width: 200%;*/
    /*height: 200%;    */
    width: 250px;
    height: 250px;
    border: 2px solid #ffffff;
    float: left;
    perspective: 400px;
}

.home-text {
    position: absolute;
    top: var(--boxAboutUsMainScr);
    width: var(--widthMainScr);
    /*height: 80%;*/
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    justify-content: space-around;
    z-index: 0;
    background: var(--mainBoxColor);
    padding-left: 40px;
    padding-right: 40px;
    /*padding-bottom: 20%;*/
}

.home-prices_bg {
    position: absolute;
    top: var(--boxPricesMainScr);
    width: var(--widthMainScr);
    /*height: 80%;*/
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    justify-content: space-around;
    z-index: 0;
    background: var(--mainBoxColor);
    padding-left: 40px;
    padding-right: 40px;
    /*padding-bottom: 20%;*/
}

.home-prices_bg li {
    text-align: left;
    position: relative;
    left: 150px;
}

.home-prices_bg li::marker {
    color: #62981f;
}

.home-google_bg {
    position: absolute;
    top: var(--googleBoxMainScr);
    width: var(--widthSmallScr);
    /*height: 20%;*/
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    justify-content: space-around;
    z-index: 0;
    background: var(--mainBoxColor);
    padding-left: 40px;
    padding-right: 40px;

}

.list-items{
    align-items: start;
}

.google-maps iframe{
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
}

.google-maps{
    display: flex;
}

/*------- Animation -------*/
@keyframes fade {
    0%{
        opacity: 0;
        margin-left: 3em;
    }
    100%{
        opacity: 1;
    }
}

@media only screen and (min-width: 1500px){
    .front_image{
        position: absolute;
        top: var(--logoTopBigScr);
        width: 70%;
        height: 50%;
    }

    .slideshow {
        top: var(--slideshowBigScr);
        width: var(--widthBigScr);
    }

    .home-text {
        position: absolute;
        top: var(--boxAboutUsBigScr);
        width: var(--widthBigScr);
    }

    .home-prices_bg {
        position: absolute;
        top: var(--boxPricesBigScr);
        width: var(--widthBigScr);
    }

    .home-prices_bg li {
        text-align: left;
        position: relative;
        left: 400px;
    }

    .home-google_bg{
        top: var(--googleBoxBigScr);
        width: var(--widthBigScr);
        height: 100%;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1500px ) {
    .front_image{
        position: absolute;
        top: var(--logoMainScreen);
        width: 70%;
        height: 50%;
    }

    .slideshow {
        top: var(--slideshowMainScr);
        width: var(--widthMainScr);
    }

    .home-prices_bg {
        top: var(--boxPricesMainScr);
    }

    .home-prices_bg li {
        text-align: left;
        position: relative;
        left: 300px;
    }

    .home-google_bg{
        top: var(--googleBoxMainScr);
        width: var(--widthMainScr);
        height: 100%;
    }
}

@media only screen and (max-width: 1024px) {
    .landingpage{
        /*padding-top: 15%;*/
        padding-bottom: 5%;
    }

    .front_image_text {
        padding-top: 15%;
    }

    .front_image{
        top: var(--logoTopSmallScr);
        height: 70%;
        width: 90%;
    }

    .slideshow {
        top: var(--slideshowSmallScr);
        width: var(--widthSmallScr);
    }

    .home-text {
        top: var(--boxAboutUsSmallScr);
        width: 80%;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 30px;
    }

    .home-prices_bg {
        top: var(--boxPricesSmallScr1024);
        width: 80%;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 30px;
    }

    .home-google_bg{
        top: var(--googleBoxSMallScr);
        height: 65%;
        width: var(--widthSmallScr);
    }

    .google-maps iframe{
        width: 100%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .google-maps{
        display: flex;
    }

}

@media only screen and (max-width: 800px){
    .home-prices_bg {
        top: var(--pricesPhone800);
    }

    .home-prices_bg li {
        text-align: left;
        position: relative;
        left: 85px;
    }

    .home-google_bg{
        top: var(--locationPhone800);
    }
}

@media only screen and (max-width: 700px){
    .home-prices_bg {
        top: var(--pricesPhone700);
    }

    .home-prices_bg li {
        text-align: left;
        position: relative;
        left: 80px;
    }

    .home-google_bg{
        top: var(--locationPhone700);
    }
}

@media only screen and (max-width: 600px){
    .home-prices_bg {
        top: var(--pricesPhone600);
    }

    .home-prices_bg li {
        text-align: left;
        position: relative;
        left: 80px;
    }

    .home-google_bg{
        top: var(--locationPhone600);
    }
}

@media only screen and (max-width: 500px){
    .home-prices_bg {
        top: var(--pricesPhone);
    }

    .home-prices_bg li {
        text-align: left;
        position: relative;
        left: 60px;
    }

    .home-google_bg{
        top: var(--googlePhone);
    }
}

@media only screen and (max-width: 400px){
    .home-prices_bg {
        top: var(--pricesPhoneSmall);
    }

    .home-google_bg{
        top: var(--googlePhoneSmall);
    }
}