:root{
    --footerMedium: 1750px;
    --footerPhone1024: 1700px;
    --footerPhone800: 1750px;
    --footerPhone700: 1950px;
    --footerPhone600: 2100px;
    --footerPhone500: 2200px;
    --footerPhone400: 2350px;


}

.contacts {
    position: absolute;
    height: 50%;
    width: 70%;
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    justify-content: space-around;
    z-index: 0;
    background: rgba(103, 154, 98, 0.87);
    padding-left: 40px;
    padding-right: 40px;
    /*padding-bottom: 20%;*/
}

.social-icons{
    display: flex;
    justify-content: center;
}

.social-icons a{

    text-decoration: none;
    padding: 10px;
    /*background-color: white;*/
    margin: 15px;
    border-radius: 35%;
}

.social-icons a i{
    font-size: 2em;
    color: black;
    opacity: 0.9;
}

.social-icons a:hover{
    background-color: #111;
    transition: 0.5s;
}

.social-icons a:hover i{
    color: white;
    transition: 0.5s;
}

.footer_copyright p {
    color: rgba(217, 204, 204, 0.7);
}

.footer_copyright{
    background-color: rgba(0, 0, 0, 0.55);
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding: 25px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    /*top: var(--footerPhone);*/
}

@media only screen and (min-width: 1024px){
    .contacts{
        top: var(--footerMedium);
    }
}

@media only screen and (max-width: 1024px) {
    .contacts{
        top: var(--footerPhone1024);
    }

    .contacts{
        height: 45%;
        width: 100%;
    }
}

@media only screen and (max-width: 800px){
    .contacts{
        top: var(--footerPhone800);
    }

    .contacts{
        height: 45%;
        width: 100%;
    }
}

@media only screen and (max-width: 700px){
    .contacts{
        top: var(--footerPhone700);
    }

    .contacts{
        height: 45%;
        width: 100%;
    }
}

@media only screen and (max-width: 600px){
    .contacts{
        top: var(--footerPhone600);
    }

    .contacts{
        height: 45%;
        width: 100%;
    }
}

@media only screen and (max-width: 500px){
    .contacts{
        top: var(--footerPhone500);
    }

    .contacts{
        height: 45%;
        width: 100%;
    }
}

@media only screen and (max-width: 400px){
    .contacts{
        top: var(--footerPhone400);
    }

    .contacts{
        height: 45%;
        width: 100%;
    }
}